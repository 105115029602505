<template>
  <div class="container email-container">
    <div
      v-if="isLoading"
      class="email-loading"
    >
      Checking...
    </div>
    <section
      v-else
      class="section email-section"
    >
      <div>
        <img
          :src="`/images/${image}`"
          alt="Email Image Icon"
          class="email-image"
        >
      </div>
      <div class="email-title">
        {{ emailTitle }}
      </div>
      <div class="email-subtitle">
        {{ emailSubtitle }}
      </div>
      <Button
        :text="emailButton"
        :custom-padding="true"
        class="custom-padding email-button"
        @click="toDeepLink()"
      />
    </section>
  </div>
</template>

<script>
import axios from 'axios';
import Button from '../../components/new-button/Button.vue';

export default {
  components: {
    Button,
  },
  data() {
    return {
      image: 'email_verification_success.svg',
      emailTitle: 'Yay, Verifikasi email berhasil!',
      emailSubtitle:
        'Sekarang kamu bisa mendapatkan invoice/tagihan dan receipt/tanda terima setelah melakukan transaksi',
      emailButton: 'Kembali ke Halaman Utama',
      deepLink: 'lionparcel://app/profile',
      isLoading: true,
    };
  },
  created() {
    if (this.isMobile()) {
      this.emailButton = 'Buka Aplikasi Lion Parcel';
    }
    this.checkVerificationCode(this.$route.params.token);
  },
  methods: {
    async checkVerificationCode(token) {
      const url = process.env.VUE_APP_ALGO_DOMAIN;

      try {
        await axios.get(
          `${url}/v1/account/auth/customer/email_verification/check`,
          {
            params: {
              token,
            },
            headers: {
              'Content-Type': 'application/json',
            },
          },
        );
      } catch (error) {
        this.image = 'email_verification_failed.svg';
        this.emailTitle = 'Verifikasi email gagal';
        this.emailSubtitle = 'Link melewati batas waktu, silakan request ulang verifikasi email kamu di aplikasi ya!';
      } finally {
        this.isLoading = false;
      }
    },
    toDeepLink() {
      window.location = this.deepLink;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./style.scss";
</style>
